import React from 'react'
import style from './Hero.module.scss'
import {
  Container,
  Row,
  Col,
  RawHtml,
  FancyShow
} from '@kogk/common'
import cn from 'classnames'

const Hero = ({ text, heading }) => {
  return (
    <div className={style.container}>
      <Container>
        <Row>
          <Col col={{ lg: 9 }}>
            <FancyShow>
              <div className={cn('eyebrow pb-2 mb-lg-1', style.heading)}>{heading}</div>
              <RawHtml>
                {text}
              </RawHtml>
            </FancyShow>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero
