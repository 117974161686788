import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/DefaultLayout/DefaultLayout.js";
import Hero from './Hero';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1>{`This is the Hero test`}</h1>
    <Hero caption="<h1>Imma hero</h1>" imageAlt="Alt text" imageUrlDesktop="https://images.prismic.io/kogk-demo/f0f7d8da-8808-4c61-9b1a-2181d04537ff_Solar+Roof+Tiles.jpg?auto=compress,format&rect=408,0,1592,796&w=1440&h=720" imageUrlMobile="https://images.prismic.io/kogk-demo/f0f7d8da-8808-4c61-9b1a-2181d04537ff_Solar+Roof+Tiles.jpg?auto=compress,format&rect=1333,0,667,1333&w=720&h=1440" mdxType="Hero" />

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      